Vue.component('vue-hero-carousel', {
    delimiters: ['${', '}'],

    template: '#vue-hero-carousel-template',

    props: [
        'slides',
    ],

    data() {
        return {
            delay: 8000,
            interval: null,
            visible_slide: 0,
        }
    },

    methods: {
        getTextColorClass(slide) {
            return 'hero-carousel__text--' + (slide.textColor ? slide.textColor : 'white');
        },
        getTextPositionHorizontalClass(slide) {
            return 'hero-carousel__text--' + (slide.textPositionHorizontal ? slide.textPositionHorizontal : 'center');
        },
        getTextPositionVerticalClass(slide) {
            return 'hero-carousel__text--' + (slide.textPositionVertical ? slide.textPositionVertical : 'middle');
        },
        imageIsShort(slide) {
            var wrapper_aspect_ratio = .40;
            var image_aspect_ratio = slide.imageHeight / slide.imageWidth

            return image_aspect_ratio < wrapper_aspect_ratio;
        },
        nextSlide() {
            this.visible_slide = this.visible_slide + 1;

            if (this.visible_slide > this.slides.length - 1) {
                this.visible_slide = 0;
            }
        },
        selectSlide(index) {
            this.clearInterval();
            this.visible_slide = index;
            this.setInterval();
        },
        setInterval() {
            this.interval = setInterval(() => {
                this.nextSlide();
            }, this.delay);
        },
        clearInterval() {
            clearInterval(this.interval);
        },
        clickSlide() {
            if (this.slides[this.visible_slide].link) {
                window.location = this.slides[this.visible_slide].link;
            }
        },
    },

    created() {
        this.setInterval();
    },
});
